/* eslint-disable max-len,react/no-danger */
import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { PageHeader, Seo, Footer } from "components/common";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import styles from "components/commonStyles";
import { siteUrl } from "configs/constants";
import Img from "gatsby-image";
import classnames from "classnames";
import { navigate } from "@reach/router";

const MakeSale = ({ classes }) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile(
          filter: {
            extension: { regex: "/(jpg)|(jpeg)|(png)/" }
            relativeDirectory: { eq: "other/make-sale" }
          }
        ) {
          edges {
            node {
              name
              childImageSharp {
                fluid(srcSetBreakpoints: [200, 340, 520, 800, 890]) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const images = data.images.edges.reduce((summ, node) => {
        const { childImageSharp, name } = node.node;

        summ[`${name}`] = childImageSharp.fluid;

        return summ;
      }, {});

      const linkColor = classnames(classes.link, classes.textColor);

      const clickButton = () => {
        navigate("#discount");
      };

      const Page = () => (
        <div className={classes.content}>
          <p>
            How to print a receipt? How to report historical information about
            sale? What’s calculation rules?
          </p>
          <h4>Basics</h4>
          <ul>
            <li>Netto – price without tax</li>
            <li>Brutto – price with tax</li>
            <li>
              Vat – tax amount measured in currency amount
              <Img fluid={images[1]} style={{ maxWidth: 150 }} />
            </li>
            <li>
              Vat rate – tax amount measured in percent
              <Img fluid={images[2]} style={{ maxWidth: 180 }} />
            </li>
          </ul>
          <h4>Receipt content</h4>
          <Img
            fluid={images.check}
            style={{ maxWidth: 331, marginBottom: 60 }}
          />
          <ul style={{ marginBottom: 60 }}>
            <li>
              General information ( cash register name , cashier name, receipt
              number, date of receipt)
            </li>
            <li>Products list where each list item is called “Sale product”</li>
            <li>
              Sale product contains: Product name, Amount, Price, optional
              Discount and other fields
            </li>
            <li>Receipt total</li>
            <li>Vat rate sums</li>
            <li>Additional information (CU serial, corporate Id)</li>
          </ul>
          <h4 id="discount">Discount</h4>
          <ul style={{ marginBottom: 60 }}>
            <li>
              Discount may be given to the whole receipt, as well to a a
              specific sale product
            </li>
            <li>
              Discount is either Absolute or Relative but not both at the same
              time
            </li>
            <li>
              <p>Relative. Discount amount is measured in percents</p>
              <Img fluid={images[3]} style={{ maxWidth: 226 }} />
              <p>and here is how butto calculated with the discount:</p>
              <Img fluid={images[4]} style={{ maxWidth: 337 }} />
              <p>where</p>
              <p>vanillabrutto = f(netto, vat)</p>
            </li>
            <li>
              <p>Absolute. Discount amount is measured in currency</p>
              <Img fluid={images[5]} style={{ maxWidth: 269 }} />
              <p>
                but it’s a bit harder to calculate butto using absolte discount.
                The idea that the absolute discount is given to every sale
                product depending on it’s sale vanila brutto
              </p>
              <Img fluid={images[6]} style={{ maxWidth: 341 }} />
            </li>
          </ul>
          <h4>Sale product</h4>
          <p style={{ marginBottom: 8 }}>
            Sale product represents a line in a receipt for every product and
            defines:
          </p>
          <ul style={{ marginBottom: 60 }}>
            <li>absolute discount</li>
            <li>relative discount</li>
            <li>
              discount – general amount of the discount measured in currency
            </li>
            <li>deltaunit – defines whether the good is measureable or not</li>
            <li>vanilla delta – amount of goods</li>
            <li>
              vanilla brutto – price of all the products with tax included, but
              without discount:
              <Img fluid={images[7]} style={{ maxWidth: 526 }} />
            </li>
            <li>netto – price without tax but including discount</li>
            <li>vat – amount of tax measured in currency including discount</li>
            <li>
              brutto – price with tax and discount:
              <Img fluid={images[8]} style={{ maxWidth: 525 }} />
            </li>
            <li>
              effective netto – price without tax but with the discount per sale
              product and with the discount for the whole receipt
            </li>
            <li>
              effective vat – tax measured in currency with the discount per
              sale product and with the discount for the whole receipt
            </li>
            <li>
              effective brutto – price with tax but with the discount per sale
              product and with the discount for the whole receipt
            </li>
          </ul>
          <h4>Vat rate sum</h4>
          <p style={{ marginBottom: 8 }}>
            Vat rate sum defines how receipt total is splitted between vat
            rates. Each vat rate sum contains:
          </p>
          <ul style={{ marginBottom: 60 }}>
            <li>VAT rate</li>
            <li>
              vanilla VAT – sum of all VAT amount measured in currency of every
              sale product for this VAT class
            </li>
            <li>
              vanilla netto – sum of all netto of every sale product for this
              VAT class
            </li>
            <li>
              vanilla brutto – sum of all brutto of every sale product for this
              VAT class
            </li>
            <li>
              vat – sum of all VAT of every sale product for this VAT class
              including discount of the whole receipt. In vat rate sum entry
              it’s calculated as
              <Img fluid={images[9]} style={{ maxWidth: 151 }} />
            </li>
            <li>
              netto – sum of all netto of every sale products for this VAT class
              including discount of the whole receipt.
              <Img fluid={images[10]} style={{ maxWidth: 151 }} />
            </li>
            <li>
              brutto – sum of all brutto of every sale products for this VAT
              class including discount of the whole receipt. To calculate brutto
              distract discount from vanilla brutto, which is calculated as
              described at{" "}
              <span className={linkColor} onClick={clickButton}>
                Discount chapter
              </span>{" "}
              above.
            </li>
          </ul>
          <h4>Sale</h4>
          <p style={{ marginBottom: 8 }}>
            Sale is an entity that represents the whole receipt. Sale is a
            synonym of receipt. It contains following attributes:
          </p>
          <ul style={{ marginBottom: 60 }}>
            <li>Vanilla brutto – sum of all brutto of every sale product</li>
            <li>Sale absolute discount</li>
            <li>Sale relative discount</li>
            <li>Sale discount – total discount measured in currency</li>
            <li>
              Sale brutto – sum of all brutto of every sale product including
              all taxes and discounts
            </li>
            <li>
              Sale vat – amount of tax measured in currency of all sale products
              including all taxes and discounts
            </li>
          </ul>
          <h4>Currency format and calculation rules</h4>
          <p style={{ marginBottom: 8 }}>
            Server makes calculations based on information to validate Sale
            entity. Here is some rules that API and server use:
          </p>
          <ul style={{ marginBottom: 30 }}>
            <li>all calculations are made using ordinal numbers only</li>
            <li>
              all currencies are kept with two digit percision (and multiplied
              to 100 in order to be kept as ordinal)
            </li>
            <li>all percents are between 0 and 100 including</li>
            <li>the result of calculation is rounded to nearest ordinal</li>
          </ul>
          <p>For instance the following formula</p>
          <Img fluid={images[11]} style={{ maxWidth: 373 }} />
          <p>becomes</p>
          <Img fluid={images[12]} style={{ maxWidth: 292 }} />
          <p style={{ marginBottom: 60 }}>at the server.</p>
          <h4>Rounding issue</h4>
          <p style={{ marginBottom: 20 }}>
            Due to the fact that all the fields are rounded down to two digits
            after point there are rounding errors. The calculation graph has
            cycle reference (the fork) therefore when two ways comes together
            there can be a rounding cent (or few cents) and they have to be
            handled.
          </p>
          <p style={{ marginBottom: 20 }}>
            Sale.brutto is the target point where two branches ends and can be
            different for each branch. One branch is where brutto is sum of all
            vat rate sums. Another branch is where brutto is sum of all
            effective brutto.
          </p>
          <p style={{ marginBottom: 20 }}>
            Here is an example: let’s take three products $30 each. And there is
            a Sale.discount 33%. Sale brutto becomes $10, but effective brutto
            of every product can’t be nor $3.33 neither $3.34 because $3.33 ×3 ≠
            $10 and $3.34 ×3 ≠ $10.
          </p>
          <p style={{ marginBottom: 20 }}>
            There is a solution: it is allowed to change every effective field
            (effective brutto, effective netto, effective vat) after the
            calculation is done. But never more than 1 cent.
          </p>
          <p>Example above will be solved as 3.33$ + 3.33$ + 3.34$ = 10$.</p>
          <h4>Full working example</h4>
          <p>…with formulas can be found at the spreadsheet.</p>
        </div>
      );

      return (
        <>
          <Seo title="Make sale" url={`${siteUrl}/make-sale`} />
          <PageHeader />
          <Page />
          <Footer />
        </>
      );
    }}
  />
);

MakeSale.propTypes = {
  classes: PropTypes.shape({
    content: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired
  }).isRequired
};

export default withStyles(styles, { withTheme: true })(MakeSale);
